export interface ClientTheme {
  primaryColorHexCode: string;
  primaryColorHoverBgHexCode: string;
  primaryColorHoverTextHexCode: string;
  secondaryColorHexCode: string;
  secondaryColorHoverBgHexCode: string;
  secondaryColorHoverTextHexCode: string;
  logoSmallUri: string;
  logoLargeUri: string;
  faviconUri: string;
  documentTitleText: string;
}

export const defaultClientTheme: ClientTheme = {
  logoLargeUri:
    'clearsignlogo.png',
  logoSmallUri:
    'clearsignlogo.png',
  primaryColorHexCode: '#111111',
  primaryColorHoverBgHexCode: '#DE3341',
  primaryColorHoverTextHexCode: '#FFFFFF',
  secondaryColorHexCode: '#111111',
  secondaryColorHoverBgHexCode: '#F2F2F2',
  secondaryColorHoverTextHexCode: '#FFFFFF',
  faviconUri: 'assets/favicon.ico',
  documentTitleText: 'Clear Sign | Rocket Mortgage',
};
